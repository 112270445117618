import { Trans } from '@lingui/macro'
import styled from 'styled-components'
import { ExternalLink, ThemedText } from 'theme/components'

const StyledLink = styled(ExternalLink)`
  font-weight: 535;
  color: ${({ theme }) => theme.neutral2};
`

export default function PrivacyPolicyNotice() {
  return (
    <ThemedText.BodySmall color="neutral2" className='wallet_footer'>

      <Trans>Why don't I see my wallet?  Click here to &apos;</Trans>{' '}
      <StyledLink href="#" className='wallet_term'>
        <Trans>Learn more</Trans>{' '}
      </StyledLink>
     
      <StyledLink href="#" className='wallet_connec_t'>Connect</StyledLink>
      <div className='con_c'>
      <Trans>Don’t have a Wallet ? &apos;</Trans>{' '}
      <StyledLink href="#" className='wallet_term'>
        <Trans>Create Wallet</Trans>{' '}
      </StyledLink>
      </div>
       
      {/* <Trans>and consent to its</Trans>{' '}
      <StyledLink href="https://uniswap.org/privacy-policy">
        <Trans>Privacy Policy.</Trans>
      </StyledLink>
      <LastUpdatedText>
        {' ('}
        <Trans>Last updated</Trans>
        {` ${LAST_UPDATED_DATE})`}
      </LastUpdatedText> */}
    </ThemedText.BodySmall>
  )
}
