import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ProtectFireblock({ children }: any) {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

  const navigate = useNavigate();

  const {
    error,
    isAuthenticated,
    isLoading,
    user,
    // Auth methods:
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    getIdTokenClaims,
    loginWithRedirect,
    loginWithPopup,
    logout,
  } = useAuth0();

  useEffect(() => {
    if (error) {
      // Handle Auth0 errors gracefully
      console.error(
        "Auth0 Error:",
        error.message,
        "**************************"
      );
    }
  }, [error]);
  const onRedirectCallback = (appState: any) => {
    toast.success("Logged In Successfully...");
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId)) {
    return null;
  }

  // http://localhost:3000/fireblock
  // https://app.ggtoro.com/fireblock

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      onRedirectCallback={onRedirectCallback}
      authorizationParams={{
        redirect_uri: "https://app.ggtoro.com/fireblock",
      }}
    >
      <ToastContainer />
      {children}
    </Auth0Provider>
  );
}
