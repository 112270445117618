import { Trans } from '@lingui/macro'
import { useScreenSize } from 'hooks/useScreenSize'
import { Link } from 'react-router-dom'
import { useTogglePrivacyPolicy } from 'state/application/hooks'
import styled, { css } from 'styled-components'
import { ExternalLink } from 'theme/components'

import { Wiggle } from '../components/animations'
import { Body1, Box, H3 } from '../components/Generics'
import { Discord, Github, Twitter } from '../components/Icons'
import { BsTwitter , BsDiscord } from "react-icons/bs";
import { BiLogoTelegram ,BiLogoLinkedin  } from "react-icons/bi";
import { AiOutlineMedium } from "react-icons/ai";
import { ImReddit } from "react-icons/im";


const SocialIcon = styled(Wiggle)`
  flex: 0;
  fill: ${(props) => props.theme.neutral1};
  cursor: pointer;
  transition: fill;
  transition-duration: 0.2s;
  &:hover {
    fill: ${(props) => props.$hoverColor};
  }
`
// const RowToCol = styled(Box)`
//   height: auto;
//   flex-shrink: 1;
//   @media (max-width: 768px) {
//     flex-direction: column;
//   }
// `
// const HideWhenSmall = styled(Box)`
//   @media (max-width: 768px) {
//     display: none;
//   }
// `
// const HideWhenLarge = styled(Box)`
//   @media (min-width: 768px) {
//     display: none;
//   }
// `
// const MenuItemStyles = css`
//   padding: 0;
//   margin: 0;
//   text-align: left;
//   font-family: Basel;
//   font-size: 16px;
//   font-style: normal;
//   font-weight: 500;
//   line-height: 24px;
//   color: ${({ theme }) => theme.neutral2};
//   stroke: none;
//   transition: color 0.1s ease-in-out;
//   text-decoration: none;
//   &:hover {
//     color: ${({ theme }) => theme.neutral1};
//     opacity: 1;
//   }
// `
// const StyledInternalLink = styled(Link)`
//   ${MenuItemStyles}
// `
// const StyledExternalLink = styled(ExternalLink)`
//   ${MenuItemStyles}
// `
// const DownloadLink = styled.a`
//   ${MenuItemStyles}
// `
// const ModalItem = styled.div`
//   ${MenuItemStyles}
//   cursor: pointer;
//   user-select: none;
// `
export function Socials({ iconSize }: { iconSize?: string }) {
  return (
    <></>
    // <Box gap="24px">
    //   <SocialIcon $hoverColor="#00C32B">
    //     <StyledExternalLink href="https://github.com/Uniswap">
    //       <Github size={iconSize} fill="inherit" />
    //     </StyledExternalLink>
    //   </SocialIcon>
    //   <SocialIcon $hoverColor="#20BAFF">
    //     <StyledExternalLink href="https://twitter.com/Uniswap">
    //       <Twitter size={iconSize} fill="inherit" />
    //     </StyledExternalLink>
    //   </SocialIcon>
    //   <SocialIcon $hoverColor="#5F51FF">
    //     <StyledExternalLink href="https://discord.com/invite/uniswap">
    //       <Discord size={iconSize} fill="inherit" />
    //     </StyledExternalLink>
    //   </SocialIcon>
    // </Box>
  )
}

export function Footer() {
  // const screenIsLarge = useScreenSize()['lg']
  // const togglePrivacyPolicy = useTogglePrivacyPolicy()

  return (

    <>
    <footer className='Footer'>
      <div className="inner-footer">

      <div>
      <div className="ft1">
        <img src="/images/logo.png" style={{width:"150px", borderRadius:"0%"}}/>
        <div className="social">
          <a href="#"> <img src="/images/1.png" width={16} height={16} /></a>
          <a href="#"><img src="/images/2.png" width={16} height={16} /></a>
          <a href="#"><img src="/images/3.png" width={16} height={16} /></a>
  
          {/* <a href="#"><BsTwitter /></a>
          <a href="#"><BiLogoTelegram /></a>
          <a href="#"><BsDiscord /></a>
          <a href="#"><AiOutlineMedium /></a>
          <a href="#"><ImReddit /></a>
          <a href="#"><BiLogoLinkedin /></a> */}
        </div>
        </div>

        
      </div>
      <div className='footer_con'>

      <div className="ft">
        <div className="heding">
          <h2>Resources</h2>
        </div>
        <div className="Text">
          <a href="#">Audit</a>
          <a href="#">Contact us</a>
          <a href="#">Contract us</a>
          <a href="#">Documentation</a>
        </div>
      </div>

      <div className="ft">
        <div className="heding">
          <h2>Help</h2>
        </div>
        <div className="Text">
          <a href="#">FAQ</a>
          <a href="#">Guides</a>
          <a href="#">Support</a>
        </div>
      </div>

      <div className="ft">
        <div className="heding">
          <h2>Tools</h2>
        </div>
        <div className="Text">
          <a href="#">Analytics</a>
          <a href="#">Bridge</a>
          <a href="#">CoinGecko</a>
          <a href="#">DexTools</a>
          <a href="#">Gecko Terminal</a>
          <a href="#">Governance</a>
        </div>
      </div>

      <div className="ft">
        <div className="heding">
          <h2>Ecosystem</h2>
        </div>
        <div className="Text">
          <a href="#">Create a Nitro pool</a>
          <a href="#">My Nitro pool</a>
        </div>
      </div>
      <div className="ftbtn">
        <a href="#"><span><img src="/images/ftpic.png"/></span> $1,467,653</a>
        <a href="#"><span>TVL</span> $104.1M</a>
      </div>
      </div>
      </div>

      

    </footer>
    
    
    
   
{/*     
    <Box as="footer" direction="column" align="center" padding={screenIsLarge ? '0 40px' : '0 48px'}>
      <Box direction="row" maxWidth="1280px" gap="24px">
        <RowToCol direction="row" justify-content="space-between" gap="32px">
          <Box direction="column" height="100%" gap="64px">
            <Box direction="column" gap="10px">
              <H3>© 2024</H3>
              <H3>GGTORO</H3>
            </Box>
            <HideWhenSmall>
              <Socials />
            </HideWhenSmall>
          </Box>

          <RowToCol direction="row" height="100%" gap="16px">
            <Box direction="row" gap="16px">
              <Box direction="column" gap="10px">
                <Body1>App</Body1>
                <StyledInternalLink to="/swap">
                  <Trans>Swap</Trans>
                </StyledInternalLink>
                <StyledInternalLink to="/tokens/ethereum">
                  <Trans>Tokens</Trans>
                </StyledInternalLink>
                <StyledInternalLink to="/nfts">
                  <Trans>NFTs</Trans>
                </StyledInternalLink>
                <StyledInternalLink to="/pool">
                  <Trans>Pool</Trans>
                </StyledInternalLink>
              </Box>

              <Box direction="column" gap="10px">
                <Body1>
                  <Trans>Protocol</Trans>
                </Body1>
                <StyledExternalLink href="https://uniswap.org/governance">
                  <Trans>Governance</Trans>
                </StyledExternalLink>
                <StyledExternalLink href="https://uniswap.org/developers">
                  <Trans>Developers</Trans>
                </StyledExternalLink>
              </Box>

            </Box>

            <Box direction="row" gap="16px">
              <Box direction="column" gap="10px">
                <Body1>
                  <Trans>Company</Trans>
                </Body1>
                <StyledExternalLink href="https://boards.greenhouse.io/uniswaplabs">
                  <Trans>Careers</Trans>
                </StyledExternalLink>
                <StyledExternalLink href="https://blog.uniswap.org/">
                  <Trans>Blog</Trans>
                </StyledExternalLink>
                <DownloadLink href="https://github.com/Uniswap/brand-assets/raw/main/Uniswap%20Brand%20Assets.zip">
                  <Trans>Brand Assets</Trans>
                </DownloadLink>
                <ModalItem onClick={togglePrivacyPolicy}>
                  <Trans>Terms & Privacy</Trans>
                </ModalItem>
                <StyledExternalLink href="https://uniswap.org/trademark">
                  <Trans>Trademark Policy</Trans>
                </StyledExternalLink>
              </Box>
              <Box direction="column" gap="10px">
                <Body1>
                  <Trans>Need help?</Trans>
                </Body1>
                <StyledExternalLink href="https://support.uniswap.org/hc/en-us/requests/new">
                  <Trans>Contact us</Trans>
                </StyledExternalLink>
                <StyledExternalLink href="https://support.uniswap.org/hc/en-us">
                  <Trans>Help Center</Trans>
                </StyledExternalLink>
              </Box>
              <Box direction="column" gap="10px">
                <Body1>
                  <Trans>Need help?</Trans>
                </Body1>
                <StyledExternalLink href="https://support.uniswap.org/hc/en-us/requests/new">
                  <Trans>Contact us</Trans>
                </StyledExternalLink>
                <StyledExternalLink href="https://support.uniswap.org/hc/en-us">
                  <Trans>Help Center</Trans>
                </StyledExternalLink>
              </Box>
            </Box>

          </RowToCol>
          <HideWhenLarge>
            <Socials />
          </HideWhenLarge>
        </RowToCol>
      </Box>
    </Box> */}
    </>

  )
}
